function isEqual(obj1, obj2) {
  // Check if both inputs are objects
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys in obj1 exist in obj2 and have the same value
  for (const key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // All checks pass, the objects are considered equal
  return true;
}

function arrayContainsObject(arr, targetObj) {
  for (const obj of arr) {
    if (isEqual(obj, targetObj)) {
      return true;
    }
  }
  return false;
}

export function getMonthsBetweenDates(startDate, endDate) {
  const months = [];

  // Copy the start date to a new variable for iteration
  const currentDate = new Date(startDate);

  // Loop through the dates until the current date exceeds the end date
  while (currentDate <= endDate) {
    // Get the month (0-based index) of the current date
    var month = {
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
    };

    // Add the month to the result array if it's not already included
    if (!arrayContainsObject(months, month)) {
      months.push(month);
    }

    // Move to the next month
    currentDate.setMonth(month.month + 1);

    month = {
      month: endDate.getMonth(),
      year: endDate.getFullYear(),
    };

    // Ensure we don't miss the end month if the current date exceeds the end date
    if (currentDate > endDate && !arrayContainsObject(months, month)) {
      months.push({ month: endDate.getMonth(), year: endDate.getFullYear() });
    }
  }

  return months;
}

export function reportIsInPeriod(obj, start, end) {
  const startDate = new Date(start.getFullYear(), start.getMonth());
  const endDate = new Date(end.getFullYear(), end.getMonth());
  const objDate = new Date(obj.year, obj.month);

  return objDate >= startDate && objDate <= endDate;
}

export function getMissingReportsForParticipant(participantPeriodicReports) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return participantPeriodicReports.filter((obj) => {
    if (obj.checked) return false;
    // Compare the year first
    if (obj.year < currentYear) {
      return true;
    } else if (obj.year === currentYear) {
      // If the years are the same, compare the months
      return obj.month < currentMonth;
    } else {
      return false;
    }
  });
}

export function getMissingReports(participants) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  var periodicReports = participants.map((p) => p.periodicReports).flat();

  return periodicReports.filter((obj) => {
    if (obj.checked) return false;
    // Compare the year first
    if (obj.year < currentYear) {
      return true;
    } else if (obj.year === currentYear) {
      // If the years are the same, compare the months
      return obj.month < currentMonth;
    } else {
      return false;
    }
  });
}

export function getUniqueReports(filtered) {
  const uniqueData = [];
  const uniqueCombinations = new Set();

  filtered.forEach((obj) => {
    const key = `${obj.year}-${obj.month}`;
    if (!uniqueCombinations.has(key)) {
      uniqueCombinations.add(key);
      uniqueData.push(obj);
    }
  });

  return uniqueData;
}
