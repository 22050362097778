import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import('views/auth-views/authentication/login'),
    ),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(
      () => import('views/auth-views/authentication/register'),
    ),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import('views/auth-views/authentication/forgot-password'),
    ),
  },
  {
    key: 'reset-password',
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(
      () => import('views/auth-views/authentication/reset-password'),
    ),
  },
];

export const adminRoutes = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard/*`,
    component: React.lazy(() => import('views/app-views/dashboard')),
  },
  {
    key: 'todo',
    path: `${APP_PREFIX_PATH}/todo/*`,
    component: React.lazy(() => import('views/app-views/todo')),
  },
  {
    key: 'statistics',
    path: `${APP_PREFIX_PATH}/statistics/*`,
    component: React.lazy(() => import('views/app-views/statistics')),
  },
  {
    key: 'participants',
    path: `${APP_PREFIX_PATH}/participants`,
    component: React.lazy(() => import('views/app-views/participants')),
  },
  {
    key: 'participant-detail',
    path: `${APP_PREFIX_PATH}/participants/:id/*`,
    component: React.lazy(() => import('views/app-views/participant-detail')),
  },
  {
    key: 'add-participants',
    path: `${APP_PREFIX_PATH}/add-participants/*`,
    component: React.lazy(() => import('views/app-views/add-participants')),
  },
  {
    key: 'jobs',
    path: `${APP_PREFIX_PATH}/jobs/*`,
    component: React.lazy(() => import('views/app-views/jobs')),
  },
  {
    key: 'schedule-management',
    path: `${APP_PREFIX_PATH}/schedule-management/*`,
    component: React.lazy(() => import('views/app-views/schedule-management')),
  },
  {
    key: 'employers',
    path: `${APP_PREFIX_PATH}/employers/*`,
    component: React.lazy(() => import('views/app-views/employers')),
  },
  {
    key: 'report',
    path: `${APP_PREFIX_PATH}/report/*`,
    component: React.lazy(() => import('views/app-views/report')),
  },
  {
    key: 'peppol',
    path: `${APP_PREFIX_PATH}/peppol/*`,
    component: React.lazy(() => import('views/app-views/peppol')),
  },
  {
    key: 'admin',
    path: `${APP_PREFIX_PATH}/admin/*`,
    component: React.lazy(() => import('views/app-views/admin')),
  },
];

export const areaManagerRoutes = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard/*`,
    component: React.lazy(() => import('views/app-views/dashboard')),
  },
  {
    key: 'participants',
    path: `${APP_PREFIX_PATH}/participants`,
    component: React.lazy(() => import('views/app-views/participants')),
  },
  {
    key: 'participant-detail',
    path: `${APP_PREFIX_PATH}/participants/:id/*`,
    component: React.lazy(() => import('views/app-views/participant-detail')),
  },
  {
    key: 'employers',
    path: `${APP_PREFIX_PATH}/employers/*`,
    component: React.lazy(() => import('views/app-views/employers')),
  },
  // {
  //   key: 'report',
  //   path: `${APP_PREFIX_PATH}/report/*`,
  //   component: React.lazy(() => import('views/app-views/report')),
  // },
  {
    key: 'jobs',
    path: `${APP_PREFIX_PATH}/jobs/*`,
    component: React.lazy(() => import('views/app-views/jobs')),
  },
];

export const coachRoutes = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard/*`,
    component: React.lazy(() => import('views/app-views/dashboard')),
  },
  {
    key: 'participants',
    path: `${APP_PREFIX_PATH}/participants`,
    component: React.lazy(() => import('views/app-views/participants')),
  },
  {
    key: 'participant-detail',
    path: `${APP_PREFIX_PATH}/participants/:id/*`,
    component: React.lazy(() => import('views/app-views/participant-detail')),
  },
  {
    key: 'employers',
    path: `${APP_PREFIX_PATH}/employers/*`,
    component: React.lazy(() => import('views/app-views/employers')),
  },
  // {
  //   key: 'report',
  //   path: `${APP_PREFIX_PATH}/report/*`,
  //   component: React.lazy(() => import('views/app-views/report')),
  // },
  {
    key: 'jobs',
    path: `${APP_PREFIX_PATH}/jobs/*`,
    component: React.lazy(() => import('views/app-views/jobs')),
  },
];
