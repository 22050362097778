import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'calendar_events';
const CalendarEvents = {};

CalendarEvents.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

CalendarEvents.findByParticipantId = async (participantId) => {
  var result = [];

  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    var data = doc.data();
    var event = { ...data, id: doc.id };

    event.start = data.start && data.start.toDate();
    event.end = data.end && data.end.toDate();
    event.startRecur = data.startRecur && data.startRecur.toDate();
    event.endRecur = data.endRecur && data.endRecur.toDate();

    result.push(event);
  });

  return result;
};

CalendarEvents.subscribeByParticipantId = (participantId, callback) => {
  const q = query(
    collection(db, collectionName),
    where('participant_id', '==', participantId),
  );

  return onSnapshot(q, (querySnapshot) => {
    var result = [];
    querySnapshot.forEach((doc) => {
      var data = doc.data();
      var event = { ...data, id: doc.id };

      event.start = data.start && data.start.toDate();
      event.end = data.end && data.end.toDate();
      event.startRecur = data.startRecur && data.startRecur.toDate();
      event.endRecur = data.endRecur && data.endRecur.toDate();

      result.push(event);
    });

    callback(result);
  });
};

function getEventRelevantProperties(event) {
  const {
    activity,
    rrule,
    duration,
    backgroundColor,
    borderColor,
    description,
    groupId,
  } = event;
  return {
    participant_id: event.extendedProps?.participant_id,
    activity,
    rrule,
    duration,
    backgroundColor,
    borderColor,
    description,
    groupId,
  };
}

CalendarEvents.add = async (event) => {
  const eventToSave = getEventRelevantProperties(event);
  const docRef = await addDoc(collection(db, collectionName), eventToSave);
  const docSnap = await getDoc(docRef);

  var newEvent = undefined;
  if (docSnap.exists()) {
    var data = docSnap.data();
    newEvent = { ...data, id: docSnap.id };
  }

  return newEvent;
};

CalendarEvents.updateById = async (id, event) => {
  const eventRef = doc(db, collectionName, id);
  var eventToSave = getEventRelevantProperties(event);

  await setDoc(eventRef, eventToSave);
};

CalendarEvents.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default CalendarEvents;
